import { Component, OnInit } from '@angular/core';
import { 
  faCloudDownloadAlt,
  faCamera,
  faSearch,
  faVideo
 } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  faCloudDownloadAlt = faCloudDownloadAlt;
  faCamera = faCamera;
  faSearch = faSearch;
  faVideo = faVideo;

  constructor() { }

  ngOnInit(): void {
  }

}
