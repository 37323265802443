<section class="hero is-dark is-bold">
  <div class="hero-body">
    <div class="container">
      <h1 class="title home-logo has-text-primary is-1">
        <span class="icon is-large">
          <fa-icon [icon]="faCloudDownloadAlt"></fa-icon>
        </span>
        Public API Playground
      </h1>
      <h2 class="subtitle is-4">
        An almost useless website
      </h2>
    </div>
  </div>
</section>

<div class="container info-tabs">
  <div class="columns is-variable is-8">
    <div class="column">
      <a routerLink="/photos">
        <div class="box">
          <span class="icon fa-5x is-large has-text-white">
            <fa-icon [icon]="faCamera"></fa-icon>
          </span>
          <hr>
          <h1 class="title is-2 has-text-primary home-responsive">Unsplash API</h1>
          <hr>
          <h2 class="subtitle is-3 home-responsive">Gallery Search</h2>
        </div>
      </a>
    </div>
    <div class="column">
      <a routerLink="/wiki">
        <div class="box">
          <span class="icon fa-5x is-large has-text-white">
            <fa-icon [icon]="faSearch"></fa-icon>
          </span>
          <hr>
          <h1 class="title is-2 has-text-primary home-responsive">Wikipedia API</h1>
          <hr>
          <h2 class="subtitle is-3 home-responsive">Wiki Search</h2>
        </div>
      </a>
    </div>
    <div class="column">
      <a routerLink="/movie">
        <div class="box">
          <span class="icon fa-5x is-large has-text-white">
            <fa-icon [icon]="faVideo"></fa-icon>
          </span>
          <hr>
          <h1 class="title is-2 has-text-primary home-responsive">OMDb API</h1>
          <hr>
          <h2 class="subtitle is-3 home-responsive">Movie Search</h2>
        </div>
      </a>
    </div>
  </div>
</div>