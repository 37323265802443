import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../home/home.component';


const routes: Routes = [
  {
    path: 'photos',
    loadChildren: () => import('../photos-api/photos-api.module').then(m => m.PhotosApiModule)
  },
  {
    path: 'wiki',
    loadChildren: () => import('../wiki-api/wiki-api.module').then(m => m.WikiApiModule)
  },
  {
    path: 'movie',
    loadChildren: () => import('../movie-api/movie-api.module').then(m => m.MovieApiModule)
  },
  { path: '', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
