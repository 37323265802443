<section class="hero is-primary is-fullheight">
    <!-- Hero head: will stick at the top -->
    <div class="hero-head">
      <nav class="navbar">
        <div class="container">
          <div class="navbar-brand">
            <a class="navbar-item" routerLink="">
              <div class="main-logo">
                <span class="icon is-medium">
                  <fa-icon [icon]="faCloudDownloadAlt"></fa-icon>
                </span>
                Public API Playground
              </div>     
            </a>
            <span 
              class="navbar-burger burger" 
              (click)="toggleBurgerMenu()" 
              data-target="navbarMenuHeroA"
            >
              <span></span>
              <span></span>
              <span></span>
            </span>
          </div>
          <!-- MOBILE NAV DIV -->
          <div id="navbarMenuHeroA" class="navbar-menu" [ngClass]="{ 'is-active': openBurgerMenu }">
            <div class="navbar-end">
              <a
                class="navbar-item" 
                routerLink="" 
                routerLinkActive="is-active" 
                [routerLinkActiveOptions]="{ exact: true }" 
                (click)="toggleBurgerMenu()"
               >
                <span class="icon is-medium">
                  <fa-icon [icon]="faList"></fa-icon>
                </span>
                Home
              </a>
              <a 
                class="navbar-item" 
                routerLink="/photos" 
                routerLinkActive="is-active" 
                (click)="toggleBurgerMenu()"
              >
                <span class="icon is-medium">
                  <fa-icon [icon]="faCamera"></fa-icon>
                </span>
                Unsplash API
              </a>
              <a 
                class="navbar-item" 
                routerLink="/wiki"
                routerLinkActive="is-active"
                (click)="toggleBurgerMenu()"
              >
                <span class="icon is-medium">
                  <fa-icon [icon]="faSearch"></fa-icon>
                </span>
                Wikipedia API
              </a>
              <a 
                class="navbar-item" 
                (click)="toggleBurgerMenu()"
                routerLink="/movie"
                routerLinkActive="is-active"
              >
              <span class="icon is-medium">
                <fa-icon [icon]="faVideo"></fa-icon>
              </span>
                OMDb API
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  
    <!-- Hero content: will be in the middle -->
    <div class="hero-body">
      <router-outlet></router-outlet>
    </div>
    
  
    <!-- Hero footer: will stick at the bottom -->
    <div class="hero-foot">
      <div class="dropdown is-up is-hoverable">
        <div class="dropdown-trigger">
          <button class="button add-primary">
            <span class="icon">
              <fa-icon [icon]="faList"></fa-icon>
            </span>
            <span>Menu</span>
          </button>
        </div>
        <div class="dropdown-menu" id="dropdown-menu2" role="menu">
          <div class="dropdown-content no-border-radius">
            <a class="dropdown-item" (click)="LogInModal()">
              <span class="icon">
                <fa-icon [icon]="faSignInAlt"></fa-icon>
              </span>
              Log In
            </a>
            <a class="dropdown-item" (click)="signUpModal()">
              <span class="icon">
                <fa-icon [icon]="faUserPlus"></fa-icon>
              </span>
              Sign Up
            </a>
            <hr class="dropdown-divider">
            <a class="dropdown-item" (click)="aboutModal()">
              <span class="icon has-text-link">
                <fa-icon [icon]="faInfoCirlce"></fa-icon>
              </span>
              About
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

<!-- MODAL ELEMENT -->
<div class="modal" [ngClass]="{ 'is-active': toggleModal }">
  <div class="modal-background" (click)="closeModal()"></div>

  <!-- LOG IN MODAL -->
  <div class="modal-card" *ngIf="logIn"> <!-- HIDE AND REVEAL MODAL HERE -->
    <header class="modal-card-head">
      <p class="modal-card-title">Log In</p>
    </header>
    <section class="modal-card-body">
      <div class="field">
        <label class="label">Email</label>
        <div class="control has-icons-left">
          <input class="input" type="email" placeholder="e.g. shanecrows33@argh.com">
          <span class="icon is-left">
            <fa-icon [icon]="faEnvelope"></fa-icon>
          </span>
        </div>
      </div>

      <div class="field">
        <label class="label">Password</label>
        <div class="control has-icons-left">
          <input class="input" type="password" placeholder="Password">
          <span class="icon is-left">
            <fa-icon [icon]="faLock"></fa-icon>
          </span>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-success">Log In</button>
      <button class="button is-danger" (click)="closeModal()">Cancel</button>
    </footer>
  </div>


  <!-- SIGN UP MODAL -->
  <div class="modal-card" *ngIf="signUp"> <!-- HIDE AND REVEAL MODAL HERE -->
    <header class="modal-card-head">
      <p class="modal-card-title">Sign Up</p>
    </header>
    <section class="modal-card-body">
      <div class="field">
        <label class="label">Name</label>
        <div class="control has-icons-left">
          <input class="input" type="text" placeholder="e.g Shane Crows">
          <span class="icon is-left">
            <fa-icon [icon]="faUserPlus"></fa-icon>
          </span>
        </div>
      </div>
      
      <div class="field">
        <label class="label">Email</label>
        <div class="control has-icons-left">
          <input class="input" type="email" placeholder="e.g. shanecrows33@argh.com">
          <span class="icon is-left">
            <fa-icon [icon]="faEnvelope"></fa-icon>
          </span>
        </div>
      </div>

      <div class="field">
        <label class="label">Password</label>
        <div class="control has-icons-left">
          <input class="input" type="password" placeholder="Password">
          <span class="icon is-left">
            <fa-icon [icon]="faLock"></fa-icon>
          </span>
        </div>
      </div>
      
      <div class="field">
        <label class="label">Confirm Password</label>
        <div class="control has-icons-left">
          <input class="input" type="password" placeholder="Confirm Password">
          <span class="icon is-left">
            <fa-icon [icon]="faLock"></fa-icon>
          </span>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot modal-card-foot-mobile">
      <button class="button is-success">Sign Up</button>
      <button class="button is-danger" (click)="closeModal()">I don't want an account on this crap website</button>
    </footer>
  </div>

  <!-- ABOUT MODAL -->
  <div class="modal-card" *ngIf="about"> <!-- HIDE AND REVEAL MODAL HERE -->
    <header class="modal-card-head">
      <p class="modal-card-title">About</p>
    </header>
    <section class="modal-card-body">
      <article class="message is-success">
        <div class="message-body">
          <strong>Public API Playground</strong> is a website exhibiting interaction with various APIs made accessible to the public.
        </div>
      </article>
      <article class="message is-info">
        <div class="message-body">
          The remainder of this template is filled with a bunch of arbitrary text to fill empty space and experiment with HTML and CSS elements.
          <br>
          I'm <strong>impressed</strong> that you've still been reading up until this point and wasting your time. 
          I highly recommend you do something more productive with your time instead of wasting it all browsing this pathetic website.
        </div>
      </article>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-success" (click)="closeModal()">Got it!</button>
    </footer>
  </div>

  <button class="modal-close is-large" (click)="closeModal()"></button>
</div>