import { Component } from '@angular/core';
import { PhotosService } from './photos.service';
import {
	faCoffee, 
	faList, 
	faUserPlus, 
	faSignInAlt, 
	faInfoCircle, 
	faEnvelope, 
	faLock,
	faHome,
	faCamera,
	faSearch,
	faCloudDownloadAlt,
	faVideo
	
} from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: [ './app.component.css' ]
})
export class AppComponent {

	// FONT AWESOME ICONS
	faVideo = faVideo;
	faCloudDownloadAlt = faCloudDownloadAlt;
	faSearch = faSearch;
	faCamera = faCamera;
	faHome = faHome;
	faLock = faLock;
	faEnvelope = faEnvelope
	faUserPlus = faUserPlus;
	faSignInAlt = faSignInAlt;
	faInfoCirlce = faInfoCircle;
	faList = faList;
	faCoffee = faCoffee;

	// PROPERTIES
	photoSource: string;
	openBurgerMenu = false;
	title: any;
	toggleModal = false;
	signUp = false;
	logIn = false;
	about = false;

	constructor(private photos: PhotosService) {}

	grabPhoto(term: string) {
		this.photos.search(term).subscribe((photo) => {
			this.photoSource = photo;
		});
	}

	toggleBurgerMenu() {
		this.openBurgerMenu = !this.openBurgerMenu;
	}

	signUpModal() {
		this.toggleModal = true;
		this.signUp = true;
	}

	LogInModal() {
		this.toggleModal = true;
		this.logIn = true;
	}

	aboutModal() {
		this.toggleModal = true;
		this.about = true;
	}

	closeModal() {
		this.toggleModal = false;
		this.signUp = false;
		this.logIn = false;
		this.about = false;
	}
}
