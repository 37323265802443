import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { pluck } from 'rxjs/operators';

interface UnsplashResponse {
	results: {
		urls: {
			small: string;
			regular: string;
			thumb: string;
		}
	}[]
};

@Injectable({
	providedIn: 'root'
})
export class PhotosService {
	constructor(private http: HttpClient) {}

	search(term: string) {
		return this.http
			.get<any>('https://api.unsplash.com/search/photos?client_id=iZ2R2UgBrA3hI0TqJovBiEe1s0YyWORM2UiLpGVrB6s', {
				params: {
					query: term,
					orientation: 'squarish'
				}
			}).pipe(pluck('results'));
	}
}
